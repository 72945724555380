import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  gap: 12px;
  @media (max-width: 960px) {
      flex-direction: column;
  }
`

export const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 40px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

export const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) { 
    font-size: 16px;
  }
`;

const TeaserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 30px;
  justify-content: center;
`

const VideoContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const VideoFrame = styled.iframe`
  width: 99%;
  height: 99%;
  max-width: 1000px;
  background: ${({ theme }) => theme.card};
  border: 0.1px solid #36528f;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  @media (max-width: 768px) {
    padding: 10px;
  }
`

const Teaser = () => {
  return (
    <Container id="teaser">
      <Wrapper>
        <Title>Teaser Video</Title>
        <Desc>Berikut adalah teaser video Club Informatika di tahun 2024.</Desc>
        <TeaserContainer>
            <VideoContainer>
              <VideoFrame
                title="Computer Club Teaser"
                src="https://www.youtube.com/embed/St0wK3ETxEo?si=4AWU-k9UvGEDXEyh"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VideoContainer>
        </TeaserContainer>
      </Wrapper>
    </Container>
  )
}

export default Teaser
