import React from 'react'
import HomeBgAnimation from '../HomeBgAnimation'
import { HomeContainer, HomeBg, HomeLeftContainer, Img, HomeRightContainer, HomeInnerContainer, TextLoop, Title, Span, SubTitle, Button } from './HomeStyle'
import ClubLogo from '../../images/ClubLogo.png'
import Typewriter from 'typewriter-effect';

const Home = () => {
    return (
        <div id="home">
            <HomeContainer>
                <HomeBg>
                    <HomeBgAnimation />
                </HomeBg>
                <HomeInnerContainer >
                    <HomeLeftContainer id="Left">
                    <Title>AVEROS <br /> SENIOR HIGH SCHOOL</Title>
                        <TextLoop>
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: ['Informatics Club', 'Computer Club'],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>Club Informatika/Komputer adalah salah satu Ekstrakulikuler yang ada di SMA Averos dengan tujuan yaitu mengembangkan minat dan bakat siswa-siswi SMA AVEROS dalam bidang informatika/computer dan menjadikan siswa-siswi yang bisa bertahan dan  bersaing di era digital.</SubTitle>
                    </HomeLeftContainer>

                    <HomeRightContainer id="Right">
                        <Img src={ClubLogo} alt="Club Logo" />
                    </HomeRightContainer>
                </HomeInnerContainer>

            </HomeContainer>
        </div>
    )
}

export default Home