import React from 'react'
import { Nav, NavLink, NavbarContainer, NavLogo, NavItems, NavbarButton, ButtonContainer, MobileIcon, MobileMenu, MobileLink } from './NavbarStyle'
import { FaBars } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import AverosLogo from '../../images/AverosLogo.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme()
  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <a style={{ display: "flex", alignItems: "center", color: "white", marginBottom: '20;', cursor: 'pointer' }}>
            <img src={ AverosLogo } width={182} height={64} alt="Club Logo" />
          </a>
        </NavLogo>
        <MobileIcon>
          <FaBars onClick={() => {
            setIsOpen(!isOpen)
          }} />
        </MobileIcon>
        <NavItems>
          <NavLink href="#">Home</NavLink>
          <NavLink href='#divisions'>Divisions</NavLink>
          <NavLink href='#leadership'>Leadership</NavLink>
          <NavLink href='#achievement'>Achievement</NavLink>
          <NavLink href='#projects'>Projects</NavLink>
          <NavLink href='#teaser'>Teaser</NavLink>
        </NavItems>
        <ButtonContainer>
          <NavbarButton href="https://www.smaaveros.sch.id" target="_blank">School Profile</NavbarButton>
        </ButtonContainer>
        {
          isOpen &&
          <MobileMenu isOpen={isOpen}>
            <MobileLink href="#" onClick={() => {
              setIsOpen(!isOpen)
            }}>Home</MobileLink>
            <MobileLink href='#divisions' onClick={() => {
              setIsOpen(!isOpen)
            }}>Divisions</MobileLink>
            <MobileLink href='#leadership' onClick={() => {
              setIsOpen(!isOpen)
            }}>Leadership</MobileLink>
            <MobileLink href='#projects' onClick={() => {
              setIsOpen(!isOpen)
            }}>Projects</MobileLink>
            <MobileLink href='#achievement' onClick={() => {
              setIsOpen(!isOpen)
            }}>Achievement</MobileLink>
            <NavbarButton style={{padding: '10px 16px',background: `${theme.primary}`, color: 'white',width: 'max-content'}} href="https://www.smaaveros.sch.id" target="_blank">School Profile</NavbarButton>
          </MobileMenu>
        }
      </NavbarContainer>
    </Nav>
  )
}

export default Navbar