import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'
import { projects } from '../../data/constants'


const Projects = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState('all');
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>
          Berikut adalah hasil karya kami atau beberapa proyek yang kami buat.
        </Desc>
        <ToggleButtonGroup >
          {toggle === 'all' ?
            <ToggleButton active value="all" onClick={() => setToggle('all')}>All</ToggleButton>
            :
            <ToggleButton value="all" onClick={() => setToggle('all')}>All</ToggleButton>
          }
          <Divider />
          {toggle === 'web dev' ?
            <ToggleButton active value="web dev" onClick={() => setToggle('web dev')}>WEB DEV</ToggleButton>
            :
            <ToggleButton value="web dev" onClick={() => setToggle('web dev')}>WEB DEV</ToggleButton>
          }
          <Divider />
          {toggle === 'mobile dev' ?
            <ToggleButton active value="mobile dev" onClick={() => setToggle('mobile dev')}>MOBILE DEV</ToggleButton>
            :
            <ToggleButton value="mobile dev" onClick={() => setToggle('mobile dev')}>MOBILE DEV</ToggleButton>
          }
          <Divider />
          {toggle === 'game dev' ?
            <ToggleButton active value="game dev" onClick={() => setToggle('game dev')}>GAME DEV</ToggleButton>
            :
            <ToggleButton value="game dev" onClick={() => setToggle('game dev')}>GAME DEV</ToggleButton>
          }
          <Divider />
          {toggle === 'vr/ar dev' ?
            <ToggleButton active value="vr/ar dev" onClick={() => setToggle('vr/ar dev')}>VR/AR DEV</ToggleButton>
            :
            <ToggleButton value="vr/ar dev" onClick={() => setToggle('vr/ar dev')}>VR/AR DEV</ToggleButton>
          }
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === 'all' && projects
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          {projects
            .filter((item) => item.category == toggle)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Projects